import React, { useState, useEffect } from "react";
import Modal from "../../Components/Modal";
import WithTableView from "../../GenericPages/WithTableView";
import toast from "react-hot-toast";
import {
  product_video_body_keys,
  product_video_table_head,
} from "../../Utils/Data";
import callApi from "../../Apis/callApi";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../../Components/Table/Pagination";
import withRouter from "../../Components/withRouter";
import { useAdminContext } from "../../Store/adminContext";

const ProductVideo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");
  const [plan, setPlan] = useState(-1);

  console.log(admin);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getWebsiteData();
  };

  const getWebsiteData = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/admins/getWebsiteData",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        console.log(res.data.data.productVideo);
        if (res.data.data.productVideo.planType === "basic") {
          setPlan(5);
        } else if (res.data.data.productVideo.planType === "advance") {
          setPlan(20);
        } else if (res.data.data.productVideo.planType === "unlimited") {
          setPlan(100000);
        } else {
          setPlan(5);
        }

        getData();
      })
      .catch((error: any) => {
        getData();
      });
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url: `https://backend.cftcommerce.com/api/productmedia?identifier=${localStorage.getItem(
        "identifier"
      )}&page=${Number(search.get("page")) || 1}&limit=${
        Number(search.get("limit")) || 15
      }`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res.data?.productMedias);
        setData(res.data?.productMedias);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: inFocus,
          method: "delete",
          type: "productmedia",
          header: true,
        })
          .then((res) => {
            setInFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleAction}
      />

      <WithTableView
        heading="Shoppable Videos"
        head={productVideoHead(admin)}
        body={loading ? [] : [...data]}
        body_keys={productVideoKeys(admin)}
        show_btn_1={data.length < plan ? isUserAllowedToEdit(admin) : false}
        isIcon={true}
        loading={loading}
        iconType="add"
        btn1Text="Add New"
        btn1Type="filled"
        onClickHandler={() => {
          navigate("/product-video/add");
        }}
        onRowClick={(val: any) => {
          navigate(`/product-video/update/${val._id}`);
        }}
        onActionClick={(e: any, id: any) => {
          setModal(true);
          setInFocus(id);
        }}
        planError={plan === data.length}
        customElement={
          <>
            <Pagination
              pageNo={
                Number(new URLSearchParams(location.search).get("page")) || 1
              }
              changePage={(val: number) => {
                let search = new URLSearchParams(location.search);

                search.set("page", String(val));

                navigate(`/product-video?${String(search)}`);
              }}
              total={total}
              showDrop={true}
              perPage={
                Number(new URLSearchParams(location.search).get("limit")) || 15
              }
              changeDrop={(val: number) => {
                let search = new URLSearchParams(location.search);

                search.set("limit", String(val));

                navigate(`/product-video?${String(search)}`);
              }}
            />
          </>
        }
      />
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.shoppableVideo_access.write)
    return true;

  return false;
};

const productVideoKeys = (admin: any) => {
  const base = ["title"];

  if (admin.role !== "subadmin") return [...base, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.shoppableVideo_access.delete) {
      return [...base, "action:delete"];
    }
    if (admin.shoppableVideo_access.write) {
      return base;
    }
  }

  return base;
};

const productVideoHead = (admin: any) => {
  const base = [{ title: "S.No." }, { title: "Video Title" }];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.shoppableVideo_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.shoppableVideo_access.write) {
      return base;
    }
  }

  return base;
};

export default withRouter(ProductVideo);
