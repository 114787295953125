import React from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "../../Styles/products.module.css";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFile,
  faPager,
  faPlay,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import l1 from "../../Assets/testingImages/Untitled-3.png";
import l2 from "../../Assets/testingImages/texture.jpg";
import { toast } from "react-hot-toast";
import withRouter from "../../Components/withRouter";
import callApi from "../../Apis/callApi";
import axios from "axios";
import Variants from "../../Components/Products/Variants";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import Specifications from "../../Components/Products/Specifications";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { makeSlug } from "../../Utils/makeSlug";
import countries from "../../data/countries";
import { getFileName } from "../../Utils/getFileName";
import SimilarProducts from "../../Components/Products/SimilarProducts";
import EnhancedProductContent from "../../Components/Products/EnhancedProductContent";
import ImageFolder from "../../Components/ImageFolder";
import { ProductInventoryCard } from "../../Components/Products/ProductInventoryCard";
import Button from "../../Components/Button";
import DropBtn from "../../Components/DropBtn";
import CrossSellProducts from "../../Components/Products/CrossSellProducts";
import SubCategories from "../SubCategories";
import heic2any from "heic2any";
import { isVisible } from "@testing-library/user-event/dist/utils";
import Info from "../../Components/info";

type IState = {
  isVariant: boolean;
  customTitle: boolean;
  products: any;
  modal: boolean;
  combined: any[];
  preview_selected: any;
  variants: any;
  specifications: {
    options_name: string;
    options_value: string;
    isVisible: boolean;
  }[];
  title: string;
  description: string;
  mediaUrl: any[];
  pricing: {
    price: string;
    comparePrice: string;
    chargeTax: boolean;
    makingCharges: string;
    cgst: string;
    igst: string;
    sgst: string;
    inclusiveOfGST: boolean;
  };
  inventory: {
    trackQuantity: boolean;
    addQuantity: any;
    currentQuantity: any;
    safetyStock: string;
    sellOutstock: boolean;
    sku: string;
    barcode: string;
  };
  inventory_safety_stock: boolean;
  shipping: {
    weight: string;
    unit: string;
    costPerUnitWeight: string;
  };
  category: string;
  subCategory: string;
  collectionName: string;
  hsnsac: string;
  tags: any[];
  tempTags: string;
  productType: string;
  loading: boolean;
  advPricing: boolean;
  advPrice: any[];
  route: string;
  seTitle: string;
  meta: string;
  isFeatured: boolean;
  isBestSeller: boolean;
  profit: {
    typeName: string;
    value: string;
  };
  show_profit: boolean;
  requestQuote: boolean;
  isAvailable: {
    cod: Boolean;
    discount: Boolean;
  };
  countryOfOrigin: string;
  old_quantity: any;
  is_quantity_changed: boolean;
  document: any;
  similarProduct: any;
  epc: any;
  newLaunch: Boolean;
  limitedview: Boolean;
  categories: any;
  collections: any[];
  crossSellProducts: any[];
  isExchangeable: boolean;
  isReturnable: boolean;
  exchangeConstant: any;
  canChangeSlug: boolean;
};

class AddUpdateProduct extends React.Component<any, IState> {
  state = {
    isVariant: false,
    customTitle: false,
    products: [],
    variants: [],
    specifications: [],
    modal: false,
    combined: [],
    preview_selected: 0,
    title: "",
    description: "",
    mediaUrl: [],
    pricing: {
      price: "",
      comparePrice: "",
      chargeTax: false,
      cgst: "",
      igst: "",
      sgst: "",
      makingCharges: "",
      inclusiveOfGST: false,
    },

    inventory: {
      trackQuantity: false,
      addQuantity: [],
      currentQuantity: "",
      safetyStock: "",
      sellOutstock: false,
      sku: "",
      barcode: "",
    },
    inventory_safety_stock: false,
    shipping: {
      weight: "",
      unit: "",
      costPerUnitWeight: "",
    },
    category: "",
    subCategory: "",
    collectionName: "",
    hsnsac: "",
    tags: [],
    tempTags: "",
    productType: "",
    loading: false,
    advPricing: false,
    advPrice: [{ optionTitle: "", optionSpec: "", optionValue: "" }],
    route: "",
    seTitle: "",
    meta: "",
    canChangeSlug: false,
    isFeatured: false,
    isBestSeller: false,
    newLaunch: false,
    limitedview: false,
    profit: {
      typeName: "",
      value: "",
    },
    show_profit: false,
    requestQuote: false,
    isAvailable: {
      cod: true,
      discount: false,
    },
    countryOfOrigin: "",
    old_quantity: [],
    is_quantity_changed: false,
    document: {
      documentTitle: "",
      documentUrl: { isFile: false, url: "", obj: null },
      documentType: "",
    },
    similarProduct: [],
    epc: [],
    collections: [],
    categories: [],
    crossSellProducts: [],
    isExchangeable: false,
    isReturnable: false,
    exchangeConstant: null,
  };

  componentDidMount(): void {
    this.setState({ loading: true });
    if (this.props.router.params?.id || this.props.router.params?.cloneid) {
      this.getData();
    } else {
      this.setState({ canChangeSlug: true });
    }

    this.getAllProducts();
    this.getCategories();
    this.getCollections();
  }

  getCategories = () => {
    axios({
      method: "GET",
      url: `https://backend.cftcommerce.com/api/productcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=10000`,
    }).then((res: any) => {
      this.setState({ categories: res.data.productCategorys });
    });
  };

  getCollections = () => {
    axios({
      method: "GET",
      url: `https://backend.cftcommerce.com/api/productcollection/?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=10000`,
    })
      .then((res: any) => {
        console.log(res.data.productCollections);
        this.setState({ collections: res.data.productCollections });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllProducts = () => {
    axios({
      url: `https://backend.cftcommerce.com/api/products/for-admin?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res: any) => {
        console.log(res);
        this.setState({ products: res.data });
        if (!this.props.router.params?.id) {
          this.setState({ loading: false });
        }
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  getData = () => {
    axios({
      url: `https://backend.cftcommerce.com/api/products/${
        this.props.router.params.id || this.props.router.params.cloneid
      }`,
      method: "get",
    })
      .then((res) => {
        console.log("response is : ", res);
        let product_data = res.data.product;

        this.setState({
          title: this.props.router.params.cloneid ? "" : product_data.title,
          customTitle: product_data?.customTitle || false,
          loading: false,
          category: product_data.category.toLowerCase(),
          subCategory: product_data.subCategory.toLowerCase(),
          collectionName: product_data.collectionName.toLowerCase(),
          productType: product_data.productType.toLowerCase(),
          hsnsac: product_data.hsnsac,
          tags: product_data.tags,
          description: product_data.description,
          mediaUrl: product_data.mediaUrl?.map((val: any) => {
            return {
              imgUrl: val,
              imgObj: null,
            };
          }),
          pricing: {
            price: product_data.pricing.price,
            comparePrice: product_data.pricing.comparePrice,
            chargeTax: product_data.pricing.igst ? true : false,
            cgst: product_data.pricing.cgst,
            igst: product_data.pricing.igst,
            sgst: product_data.pricing.sgst,
            makingCharges: product_data.pricing.makingCharge,
            inclusiveOfGST: product_data.pricing?.inclusiveOfGST || false,
          },
          inventory: {
            trackQuantity: product_data.inventory.trackQuantity,
            addQuantity:
              product_data.inventory.addQuantity?.length === 0
                ? []
                : product_data.inventory.addQuantity,
            currentQuantity: product_data.inventory?.currentQuantity,
            sellOutstock: product_data.inventory.sellOutstock,
            sku: product_data.inventory.sku,
            barcode: product_data.inventory.barcode,
            safetyStock: product_data.inventory?.safetyStock,
          },
          shipping: {
            weight: product_data.shipping.weight,
            unit: product_data.shipping.unit,
            costPerUnitWeight: product_data.shipping.costPerUnitWeight,
          },
          specifications: product_data.specifications.map((val: any) => ({
            ...val,
            isVisible: val.hasOwnProperty("isVisible") ? val.isVisible : true,
          })),
          variants: [...product_data?.variant]?.map((val: any) => {
            return {
              options_name: val.options_name,
              options_values: val.options_value,
              images: val.images?.map((val1: any) => {
                return {
                  imageUrl: { url: val1.imageUrl, obj: null },
                  imageName: val1.imageName,
                };
              }),
              temp_text: "",
              variantProductId: val.variantProductId,
              slug: val.slug,
              optionSku: val.optionSku,
            };
          }),
          advPricing: product_data.advancePricing,
          advPrice: product_data.advancePricingValues,
          seTitle: this.props.router.params.cloneid
            ? ""
            : product_data?.seListing?.title,

          meta: product_data?.seListing?.metalDescription,
          route: this.props.router.params.cloneid
            ? ""
            : product_data?.seListing?.routeHandle,
          canChangeSlug: this.props.router.params.cloneid
            ? true
            : product_data?.seListing?.routeHandle
            ? false
            : true,
          isFeatured: product_data?.isFeatured || false,
          isBestSeller: product_data?.isBestSeller || false,
          profit: {
            typeName: product_data?.profit?.typeName,
            value: String(product_data?.profit?.value),
          },
          show_profit: product_data?.profit?.typeName ? true : false,
          requestQuote: product_data?.requestQuote || false,
          isAvailable: {
            cod: product_data?.isAvailable?.cod,
            discount: product_data?.isAvailable?.discount || false,
          },
          old_quantity: product_data?.inventory.addQuantity,
          document: {
            documentTitle: product_data?.document?.documentTitle,
            documentType: product_data?.document?.documentType,
            documentUrl: {
              isFile: product_data?.document?.documentUrl
                ? product_data?.document?.documentUrl.includes("https://")
                  ? false
                  : true
                : false,
              url: product_data?.document?.documentUrl,
              obj: null,
            },
          },
          similarProduct: product_data?.similarProduct,
          crossSellProducts: product_data?.crossSellProducts,
          epc: product_data?.epc?.map((val: any) => ({
            ...val,
            mediaUrl: { obj: null, url: val?.mediaUrl },
          })),
          newLaunch: product_data?.newLaunch,
          limitedview: product_data?.limitedview,
          countryOfOrigin: product_data?.countryOfOrigin,
          isReturnable: product_data?.isReturnable,
          isExchangeable: product_data?.isExchangeable,
          exchangeConstant: product_data?.exchangeConstant || null,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in fetching data");
        this.setState({ loading: false });
      });
  };

  handleAddVariant = () => {
    this.setState({
      variants: [
        ...this.state.variants,
        {
          options_name: "",
          options_values: [],
          images: [],
          // temp_text: "",
          variantProductId: "",
          slug: "",
          optionSku: "",
        },
      ],
    });
  };

  handleProductAddInVariant = (productId: string, index: number) => {
    console.log(productId, index);

    let temp: any = [...this.state.variants];
    let product: any = this.state.products.filter(
      (val: any) => val._id === productId
    )[0];

    console.log(product);

    temp[index].variantProductId = productId;
    temp[index].slug = product?.seListing.routeHandle;
    temp[index].optionSku = product?.inventory?.sku || "";

    this.setState({ variants: temp });
  };

  handleAddSpec = () => {
    this.setState({
      specifications: [
        ...this.state.specifications,
        { options_name: "", options_value: "", isVisible: true },
      ],
    });
  };

  handleRemoveVariant = (index: any) => {
    let temp = this.state.variants;
    temp.splice(index, 1);
    this.setState({ variants: [...temp] });
  };

  handleRemoveSpec = (index: any) => {
    let temp = this.state.specifications;
    temp.splice(index, 1);
    this.setState({ specifications: [...temp] });
  };

  handleVariantName = (event: any, index: any) => {
    let temp: any = this.state.variants;
    temp[index].options_name = event.target.value;
    this.setState({ variants: [...temp] });
  };

  handleSpecProperties = (event: any, index: any) => {
    let temp: any = this.state.specifications;
    console.log("specs : ", event);
    if (event.target.name === "isVisible") {
      temp[index][event.target.name] = event.target.value;
    } else {
      temp[index][event.target.name] = event.target.value.toLowerCase();
    }
    this.setState({ specifications: [...temp] });
  };

  handleVariantImageAdd = async (event: any, index: any) => {
    let temp: any = this.state.variants;
    const file = event.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      temp[index].images.push({
        imageUrl: URL.createObjectURL(convertedFile),
        imageObject: convertedFile,
        imageName: "",
      });
    } else
      temp[index].images.push({
        imageUrl: URL.createObjectURL(event.target.files[0]),
        imageObject: event.target.files[0],
        imageName: "",
      });

    this.setState({ variants: [...temp] });
  };

  handleVariantImageRemove = (index1: any, index2: any) => {
    let temp: any = this.state.variants;
    temp[index1].images.splice(index2, 1);
    this.setState({ variants: [...temp] });
  };

  handleVariantImageName = (index1: any, index2: any, event: any) => {
    let temp: any = this.state.variants;
    temp[index1].images[index2].imageName = event.target.value;
    this.setState({ variants: [...temp] });
  };

  createCombination = () => {
    if (this.state.variants.length === 0) {
      toast.error("Please add variants");
      return;
    }

    let v_styles: any = this.state.variants.filter(
      (val: any) => val.option_name === "style"
    )[0];

    if (!v_styles) {
      toast.error("Please add style");
      return;
    }

    let v_design: any = this.state.variants.filter(
      (val: any) => val.option_name === "design"
    )[0];

    if (!v_design) {
      toast.error("Please add Design");
      return;
    }

    if (v_styles.images.length === 0 || v_design.images.length === 0) {
      toast.error("Please add images for style and design");
      return;
    }

    let combine = [];

    for (let i = 0; i < v_styles.images.length; i++) {
      for (let j = 0; j < v_design.images.length; j++) {
        combine.push({
          style: v_styles.images[i],
          design: v_design.images[j],
          style_name: v_styles.option_values[i],
          design_name: v_design.option_values[j],
        });
      }
    }

    this.setState({
      combined: [...combine],
      modal: true,
    });
  };

  handleMediaAdd = async (event: any) => {
    const fileSize = event.target.files[0].size / 1024 / 1024; // Convert to MB
    const maxSize = 20;

    if (fileSize > maxSize) {
      toast.error("File can not be greater than 20MB");
      return;
    }

    const file = event.target.files[0];

    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      this.setState({
        mediaUrl: [
          ...this.state.mediaUrl,
          {
            imgUrl: URL.createObjectURL(convertedFile),
            imgObj: convertedFile,
          },
        ],
      });
    } else
      this.setState({
        mediaUrl: [
          ...this.state.mediaUrl,
          {
            imgUrl: URL.createObjectURL(event.target.files[0]),
            imgObj: event.target.files[0],
          },
        ],
      });
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  uploadMediaUrl = () => {
    let temp: any = this.state.mediaUrl;

    return new Promise((resolve, reject) => {
      let temp_url: any = [];

      for (let i = 0; i < temp.length; i++) {
        if (temp[i].imgObj) {
          const filename = getFileName(temp[i].imgObj);

          try {
            let _ = UploadImage({ file: temp[i].imgObj, name: filename });
            temp_url.push(filename);
            // return [filename];
          } catch (err) {
            toast.error("error occurred while uploading media url.");
            reject(err);
          }
        } else {
          temp_url.push(temp[i].imgUrl);
        }
      }

      resolve(temp_url);
    });
  };

  uploadVariantImages = () => {
    let temp_variants: any = this.state.variants;

    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < temp_variants.length; i++) {
        for (let j = 0; j < temp_variants[i].images.length; j++) {
          if (temp_variants[i].images[j].imageUrl.obj) {
            const filename = getFileName(
              temp_variants[i].images[j].imageUrl.obj
            );

            try {
              let _ = await UploadImage({
                name: filename,
                file: temp_variants[i].images[j].imageUrl.obj,
              });
            } catch (err) {
              toast.error("error occurred while uploading variants media.");
              reject();
            }

            temp_variants[i].images[j].imageUrl.url = filename;
          }
        }
      }

      temp_variants = temp_variants?.map((val: any) => {
        if (val.variantProductId) {
          return {
            options_name: val.options_name,
            options_value: val.options_values,
            variantProductId: val.variantProductId,
            slug: val.slug,
            optionSku: val.optionSku,
            images: val.images?.map((val1: any) => {
              return {
                imageUrl: val1.imageUrl.url,
                imageName: val1.imageName,
              };
            }),
          };
        }

        return {
          options_name: val.options_name,
          options_value: val.options_values,
          slug: val.slug,
          optionSku: val.optionSku,
          images: val.images?.map((val1: any) => {
            return {
              imageUrl: val1.imageUrl.url,
              imageName: val1.imageName,
            };
          }),
        };
      });

      resolve(temp_variants);
    });
  };

  uploadEpcMedia = () => {
    return new Promise(async (resolve, reject) => {
      console.log(this.state.epc);
      if (this.state.epc.length === 0) resolve([]);

      let temp: any = [...this.state.epc];

      for (let i = 0; i < temp.length; i++) {
        if (temp[i]?.mediaUrl.obj) {
          let filename = getFileName(temp[i]?.mediaUrl?.obj);

          try {
            let _ = await UploadImage({
              file: temp[i]?.mediaUrl?.obj,
              name: filename,
            });
            temp[i].mediaUrl = filename;
          } catch (err) {
            toast.error("Error occurred while uploading epc media");
            reject(err);
          }
        } else {
          temp[i].mediaUrl = temp[i]?.mediaUrl?.url;
        }
      }

      resolve(temp);
    });
  };

  uploadDocument = () => {
    return new Promise(async (resolve, reject) => {
      if (
        this.state.document.documentUrl.isFile &&
        this.state.document.documentUrl.obj
      ) {
        console.log("uploading document");
        let filename = getFileName(this.state.document.documentUrl.obj);
        let res = await UploadImage({
          file: this.state.document.documentUrl.obj,
          name: filename,
        });
        resolve(filename);
      } else {
        resolve(this.state.document.documentUrl.url);
      }
    });
  };

  createProduct = async (status?: string) => {
    // uploading images for mediaUrl
    this.setState({ loading: true });

    this.uploadMediaUrl()
      .then((res) => {
        this.uploadEpcMedia()
          .then((epc_data: any) => {
            this.uploadVariantImages()
              .then(async (temp_variants) => {
                console.log("variants - temp - is ", temp_variants);

                let d_res: any = "";

                try {
                  d_res = await this.uploadDocument();
                  console.log("file response is :: ", d_res);
                } catch (err) {
                  toast.error("Something went wrong while uploading document");
                  return;
                }

                let data = {
                  isVariant: this.state.isVariant,
                  countryOfOrigin: this.state.countryOfOrigin,
                  title: this.state.title,
                  customTitle: this.state.customTitle,
                  category: this.state.category.toLowerCase(),
                  subCategory: this.state.subCategory.toLowerCase(),
                  collectionName: this.state.collectionName.toLowerCase(),
                  productType: this.state.productType.toLowerCase(),
                  hsnsac: this.state.hsnsac,
                  tags: this.state.tags,
                  description: this.state.description,
                  mediaUrl: res, // need changes
                  isFeatured: this.state.isFeatured,
                  isBestSeller: this.state.isBestSeller,
                  newLaunch: this.state.newLaunch,
                  limitedview: this.state.limitedview,
                  pricing: {
                    ...this.state.pricing,
                    igst: Number(this.state.pricing.igst),
                    cgst: Number(this.state.pricing.igst) / 2,
                    sgst: Number(this.state.pricing.sgst) / 2,
                    price: this.state.pricing.price,
                    comparePrice: Number(this.state.pricing.comparePrice),
                    makingCharge: Number(this.state.pricing.makingCharges),
                    inclusiveOfGST: this.state.pricing.inclusiveOfGST,
                  },
                  inventory: this.state.inventory,

                  variant: temp_variants,
                  shipping: this.state.shipping,
                  identifier: localStorage.getItem("identifier"),
                  specifications: this.state.specifications.map((val: any) => ({
                    ...val,
                    options_name: val.options_name.trim(),
                    isVisible: val.isVisible,
                  })),
                  advancePricingValues: this.state.advPrice,
                  advancePricing: this.state.advPricing,
                  priority: -1,
                  seListing: {
                    title: this.state.seTitle,
                    metalDescription: this.state.meta,
                    routeHandle: this.state.route,
                  },
                  profit: {
                    typeName: this.state.profit?.typeName,
                    value: Number(this.state.profit?.value),
                  },
                  requestQuote: this.state.requestQuote,
                  isAvailable: {
                    cod: this.state.isAvailable.cod,
                    discount: this.state.isAvailable.discount,
                  },
                  status: status ? status : "active",
                  document: {
                    documentType: this.state.document.documentType,
                    documentTitle: this.state.document.documentTitle,
                    documentUrl: d_res,
                  },
                  similarProduct: this.state.similarProduct,
                  crossSellProducts: this.state.crossSellProducts,
                  epc: epc_data,
                  isReturnable: this.state.isReturnable,
                  isExchangeable: this.state.isExchangeable,
                  exchangeConstant: this.state.exchangeConstant,
                };

                console.log("data to send in products : ", data);

                axios({
                  method: "post",
                  url: "https://backend.cftcommerce.com/api/products",
                  data,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "token-BO-ADMIN"
                    )}`,
                  },
                })
                  .then((res) => {
                    console.log(res);
                    this.setState({ loading: false });
                    toast.success("Product added successfully.");
                    this.props.router.navigate("/products");
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err.response.data.message);
                  });
              })
              .catch(() => {
                toast.error("Error in uploading variant images.");
                this.setState({ loading: false });
              });
          })
          .catch(() => {});
      })
      .catch(() => {
        toast.error("Error occurred while uploading media url.");
        this.setState({ loading: false });
      });

    return;
  };

  updateProduct = (status?: string) => {
    // uploading images for mediaUrl
    this.setState({ loading: true });

    this.uploadMediaUrl()
      .then((res) => {
        this.uploadEpcMedia()
          .then((epc_data: any) => {
            this.uploadVariantImages()
              .then(async (temp_variants) => {
                let d_res: any = "";

                try {
                  d_res = await this.uploadDocument();
                  console.log("file response is :: ", d_res);
                } catch (err) {
                  toast.error("Something went wrong while uploading document");
                  return;
                }

                let data = {
                  isVariant: this.state.isVariant,
                  countryOfOrigin: this.state.countryOfOrigin,
                  title: this.state.title,
                  customTitle: this.state.customTitle,
                  category: this.state.category.toLowerCase(),
                  subCategory: this.state.subCategory.toLowerCase(),
                  collectionName: this.state.collectionName.toLowerCase(),
                  productType: this.state.productType.toLowerCase(),
                  hsnsac: this.state.hsnsac,
                  tags: this.state.tags,
                  description: this.state.description,
                  mediaUrl: res,
                  newLaunch: this.state.newLaunch,
                  limitedview: this.state.limitedview,
                  pricing: {
                    ...this.state.pricing,
                    igst: Number(this.state.pricing.igst),
                    cgst: Number(this.state.pricing.igst) / 2,
                    sgst: Number(this.state.pricing.sgst) / 2,
                    price: this.state.pricing.price,
                    comparePrice: Number(this.state.pricing.comparePrice),
                    makingCharge: Number(this.state.pricing.makingCharges),
                    inclusiveOfGST: this.state.pricing.inclusiveOfGST,
                  },
                  inventory: this.state.inventory,
                  variant: temp_variants,
                  shipping: this.state.shipping,
                  identifier: localStorage.getItem("identifier"),
                  specifications: this.state.specifications.map((val: any) => ({
                    ...val,
                    options_name: val.options_name.trim(),
                    isVisible: val.isVisible,
                  })),
                  advancePricingValues: this.state.advPrice,
                  advancePricing: this.state.advPricing,
                  seListing: {
                    title: this.state.seTitle,
                    metalDescription: this.state.meta,
                    routeHandle: this.state.route,
                  },
                  isFeatured: this.state.isFeatured,
                  isBestSeller: this.state.isBestSeller,
                  profit: {
                    typeName: this.state.profit?.typeName,
                    value: Number(this.state.profit?.value),
                  },
                  requestQuote: this.state.requestQuote,
                  isAvailable: {
                    cod: this.state.isAvailable.cod,
                    discount: this.state.isAvailable.discount,
                  },
                  status: status ? status : "active",
                  document: {
                    documentType: this.state.document.documentType,
                    documentTitle: this.state.document.documentTitle,
                    documentUrl: d_res,
                  },
                  similarProduct: this.state.similarProduct,
                  crossSellProducts: this.state.crossSellProducts,
                  epc: epc_data,
                  isReturnable: this.state.isReturnable,
                  isExchangeable: this.state.isExchangeable,
                  exchangeConstant: this.state.exchangeConstant,
                };

                axios({
                  method: "patch",
                  url:
                    "https://backend.cftcommerce.com/api/products/" +
                    this.props.router.params.id,
                  data,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "token-BO-ADMIN"
                    )}`,
                  },
                })
                  .then((res) => {
                    console.log(res);
                    toast.success("Product updated successfully.");
                    this.setState({ loading: false });
                    this.props.router.navigate(-1);
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err.response.data.message);
                    this.setState({ loading: false });
                  });
              })
              .catch(() => {});
          })
          .catch((err: any) => {});
      })
      .catch(() => {
        this.setState({ loading: false });
        toast.error("error occured while uploading media url.");
      });
  };

  handleClick = (type: any) => {
    // console.log(this.props.router);
    if (type === "default") {
      if (this.props.router.params?.id) {
        this.updateProduct();
      } else {
        this.createProduct();
      }
    } else if (type === "SAV") {
      this.setState({ isVariant: true }, () => {
        if (this.props.router.params?.id) {
          this.updateProduct();
        } else {
          this.createProduct();
        }
      });
    } else if (type === "SAD") {
      if (this.props.router.params?.id) {
        this.updateProduct("draft");
      } else {
        this.createProduct("draft");
      }
    }
  };

  handleDocument = (e: any) => {
    let temp = { ...this.state.document };
    temp.documentUrl.isFile = true;
    temp.documentUrl.obj = e.target.files[0];
    temp.documentUrl.url = e.target.files[0].name;
    this.setState({ document: temp });
  };

  trimIt = () => {
    axios({
      url: "https://backend.cftcommerce.com/api/products/?identifier=jssolitaires.in&limit=10000000",
      method: "get",
    })
      .then(async (res: any) => {
        console.log(res.data.products);
        let product_with_specification = res.data.products.filter(
          (val: any) => val.specifications.length !== 0
        );
        console.log(product_with_specification);
        for (let i = 0; i < product_with_specification.length; i++) {
          let pws = product_with_specification[i];

          console.log(pws.specifications);

          let ss = pws.specifications.map((val: any) => ({
            ...val,
            options_name: val.options_name.trim(),
            isVisible: val.hasOwnProperty("isVisible") ? val.isVisible : true,
          }));

          console.log(ss);

          let r = await axios({
            method: "patch",
            url: `https://backend.cftcommerce.com/api/products/${pws._id}`,
            data: { specifications: ss },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
            },
          });

          console.log(i, "Done");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        {!this.state.loading && (
          <Content>
            <ContentHead
              title={
                this.props.router.params?.id
                  ? "Update Product"
                  : this.props.router.params?.cloneid
                  ? "Clone Product"
                  : "Add Product"
              }
              showBtn1={false}
              showBtn2={false}
              showBtn3={false}
              btn1Name="btn1"
              btn2Name="btn3"
              btn3Name="btn2"
              btn1Text="Save"
              btn2Text="Save as Draft"
              btn3Text="Cancel"
              btn1type="filled"
              btn3type="outlined_del"
              btn2type="outlined"
              onClickHandler={this.handleClick}
              customElement={
                <div style={{ display: "flex", height: "100%", gap: "20px" }}>
                  <Button
                    text="Cancel"
                    type="outlined_del"
                    name="btn1"
                    clickHandler={() => {
                      this.props.router.navigate(-1);
                    }}
                    isIcon={false}
                    style={{ height: "100%" }}
                  ></Button>
                  {this.props.admin.role === "subadmin" &&
                  !this.props.admin.catalog_access.write ? (
                    <></>
                  ) : (
                    <DropBtn
                      text="Save"
                      isIcon={false}
                      drop_list={[
                        { id: "default", title: "Save" },
                        { id: "SAV", title: "Save as Variant" },
                        { id: "SAD", title: "Save as Draft" },
                      ]}
                      on_click={this.handleClick}
                    />
                  )}
                </div>
              }
            />
            <ContentBody>
              <div className={styles.container}>
                <div className={styles.field_container}>
                  <div className={styles.custom_title_container}>
                    <InputField
                      type={"text"}
                      isLabel={true}
                      lable="Title"
                      value={this.state.title}
                      onChange={(e: any) => {
                        if (this.props.router.params?.id) {
                          this.setState({
                            title: this.state.customTitle
                              ? e.target.value
                              : e.target.value.toLowerCase(),
                          });
                        } else {
                          this.setState({
                            title: this.state.customTitle
                              ? e.target.value
                              : e.target.value.toLowerCase(),
                            route: makeSlug(e.target.value),
                          });
                        }
                      }}
                    />
                    <div className={styles.custom_title_section}>
                      <span>Custom </span>{" "}
                      <ToggleSwitch
                        value={this.state.customTitle}
                        onClick={() =>
                          this.setState({
                            customTitle: !this.state.customTitle,
                            title: this.state.customTitle
                              ? this.state.title.toLowerCase()
                              : this.state.title,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <InputField
                      type={"select"}
                      isLabel={true}
                      lable="Category"
                      options={[
                        ...this.state.categories?.map((val: any) => ({
                          title: val.title,
                          value: val.title,
                        })),
                      ]}
                      accessText="title"
                      accessValue="value"
                      placeholder="Select Category"
                      value={this.state.category}
                      onChange={(e: any) => {
                        this.setState({
                          category: e.target.value,
                          subCategory: "",
                        });
                      }}
                    />

                    <InputField
                      type={"select"}
                      options={
                        this.state.category
                          ? this.state.categories.filter(
                              (val: any) => val.title === this.state.category
                            )[0]
                            ? this.state.categories
                                .filter(
                                  (val: any) =>
                                    val.title === this.state.category
                                )[0]
                                // @ts-ignore
                                ?.subCategory?.map((val: any) => ({
                                  title: val,
                                  value: val,
                                }))
                            : []
                          : []
                      }
                      isLabel={true}
                      lable={"Sub-Category"}
                      placeholder="Select Sub-Category"
                      accessText="title"
                      accessValue="value"
                      value={this.state.subCategory}
                      onChange={(e: any) => {
                        this.setState({
                          subCategory: e.target.value.toLowerCase(),
                        });
                      }}
                    />
                    <InputField
                      type={"select"}
                      isLabel={true}
                      options={[
                        ...this.state.collections?.map((val: any) => ({
                          title: val.title,
                          value: val.title,
                        })),
                      ]}
                      accessText="title"
                      accessValue="value"
                      lable="Collection Name"
                      placeholder="Select Collection"
                      value={this.state.collectionName}
                      onChange={(e: any) => {
                        this.setState({
                          collectionName: e.target.value,
                        });
                      }}
                    />
                    <InputField
                      type={"text"}
                      isLabel={true}
                      lable="Product Type"
                      value={this.state.productType}
                      onChange={(e: any) => {
                        this.setState({
                          productType: e.target.value.toLowerCase(),
                        });
                      }}
                    />
                    <div>
                      <InputField
                        type={"text"}
                        isLabel={true}
                        lable="Tags"
                        value={this.state.tempTags}
                        onChange={(e: any) => {
                          this.setState({
                            tempTags: e.target.value.toLowerCase(),
                          });
                        }}
                        onKeyPress={(e: any) => {
                          if (e.key === "Enter") {
                            this.setState({
                              tags: [...this.state.tags, this.state.tempTags],
                              tempTags: "",
                            });
                          }
                        }}
                      />
                      <div className={styles.variant_option_values}>
                        {this.state.tags?.map((val: any, index: any) => {
                          return (
                            <div
                              className={styles.variant_option_value}
                              style={{ marginTop: "10px" }}
                              key={index}
                            >
                              <p>{val}</p>
                              <FontAwesomeIcon
                                icon={faClose}
                                color="grey"
                                onClick={() => {
                                  let temp_tags = this.state.tags;
                                  temp_tags.splice(index, 1);
                                  this.setState({ tags: [...temp_tags] });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <InputField
                      type={"select"}
                      isLabel={true}
                      options={countries}
                      accessText="name"
                      accessValue="name"
                      lable="Country Of Origin"
                      value={this.state.countryOfOrigin}
                      onChange={(e: any) => {
                        this.setState({
                          countryOfOrigin: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="grid" style={{ paddingTop: "20px" }}>
                    <TextEditor
                      content={this.state.description}
                      handleChange={(val: any) => {
                        this.setState({ description: val });
                      }}
                    />
                  </div>
                </div>

                <div className="grid_2">
                  <div
                    className={`${styles.field_container} ${styles.media_container}`}
                  >
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Media
                      <Info
                        text="Recommended Size is 1080px x 1080px"
                        containerStyle={{ height: "1.1rem", width: "1.1rem" }}
                      />
                    </h3>
                    {!(this.state.mediaUrl.length > 0) ? (
                      <div className={styles.select_media}>
                        <p>+</p>
                        <p style={{ textAlign: "center" }}>
                          Accepts images and videos
                        </p>
                        <input
                          type="file"
                          accept=".heic,image/*,video/*"
                          onChange={this.handleMediaAdd}
                        />
                      </div>
                    ) : (
                      <div className={styles.selected_media}>
                        {this.state.mediaUrl?.map(
                          (img_data: any, index: any) => {
                            return (
                              <div key={index} className={styles.media_image}>
                                {img_data.imgObj?.type === "video/mp4" ||
                                img_data.imgUrl.endsWith(".mp4") ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlay}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        background: "transparent",
                                        transform: "translate(-50%, -50%)",
                                        height: "35px",
                                        width: "35px",
                                      }}
                                      color="#d3d3d3"
                                    />
                                    <video
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "contain",
                                      }}
                                    >
                                      <source
                                        src={
                                          this.props.router.params?.id ||
                                          this.props.router.params?.cloneid
                                            ? img_data.imgObj
                                              ? img_data.imgUrl
                                              : process.env
                                                  .REACT_APP_FAST_LINK +
                                                "/" +
                                                folder_name() +
                                                img_data.imgUrl
                                            : img_data.imgUrl
                                        }
                                      />
                                    </video>
                                  </div>
                                ) : (
                                  <img
                                    src={
                                      this.props.router.params?.id ||
                                      this.props.router.params?.cloneid
                                        ? img_data.imgObj
                                          ? img_data.imgUrl
                                          : process.env.REACT_APP_FAST_LINK +
                                            "/" +
                                            folder_name() +
                                            img_data.imgUrl
                                        : img_data.imgUrl
                                    }
                                    alt=""
                                  />
                                )}
                                <FontAwesomeIcon
                                  color="white"
                                  icon={faClose}
                                  onClick={() => {
                                    let temp_mediaUrl = this.state.mediaUrl;
                                    temp_mediaUrl.splice(index, 1);
                                    this.setState({
                                      mediaUrl: [...temp_mediaUrl],
                                    });
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                        <div className={styles.select_media}>
                          <p>+</p>
                          <p style={{ textAlign: "center" }}>Add Media</p>
                          <input
                            type="file"
                            accept=".heic,image/*,video/*"
                            onChange={this.handleMediaAdd}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card">
                    <h3 style={{ paddingBottom: "20px" }}>Documents</h3>

                    <div className="grid_2">
                      <InputField
                        type={"text"}
                        isLabel={true}
                        lable="Title"
                        value={this.state.document.documentTitle}
                        onChange={(e: any) => {
                          let temp = { ...this.state.document };
                          temp.documentTitle = e.target.value;
                          this.setState({ document: temp });
                        }}
                      />
                      <InputField
                        type={"text"}
                        isLabel={true}
                        lable="Document Type"
                        value={this.state.document.documentType}
                        onChange={(e: any) => {
                          let temp = { ...this.state.document };
                          temp.documentType = e.target.value;
                          this.setState({ document: temp });
                        }}
                      />
                      <div style={{ position: "relative" }}>
                        <InputField
                          type={"text"}
                          isLabel={true}
                          lable="Document Url/Upload"
                          value={
                            !this.state.document.documentUrl.isFile
                              ? this.state.document.documentUrl.url
                              : ""
                          }
                          onChange={(e: any) => {
                            let temp: any = { ...this.state.document };
                            temp.documentUrl = {
                              isFile: false,
                              url: e.target.value,
                              obj: null,
                            };
                            this.setState({ document: temp });
                          }}
                          placeholder="Enter Document Url"
                        />
                        <div
                          style={{
                            position: "absolute",
                            height: "50px",
                            width: "100%",
                            left: "calc(100% + 20px)",
                            bottom: "0",
                            borderRadius: "15px",
                            border: "none",
                            color: "#36619b",
                            background: "#eff5fc",
                            fontSize: ".9rem",
                          }}
                        >
                          {this.state.document.documentUrl.obj ||
                          (this.state.document.documentUrl.isFile &&
                            !this.state.document.documentUrl.obj) ? (
                            <div className={styles.document_uploaded_field}>
                              <FontAwesomeIcon icon={faFile} />
                              {this.state.document.documentUrl.url}
                              <div
                                className={styles.document_remove_btn}
                                onClick={(e: any) => {
                                  this.setState({
                                    document: {
                                      ...this.state.document,
                                      documentUrl: {
                                        isFile: false,
                                        obj: null,
                                        url: "",
                                      },
                                    },
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faClose} />
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                                position: "relative",
                              }}
                            >
                              Upload Document
                              <FontAwesomeIcon icon={faUpload} />
                              <input
                                type="file"
                                style={{
                                  opacity: "0",
                                  position: "absolute",
                                  top: "0",
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  cursor: "pointer",
                                }}
                                onChange={this.handleDocument}
                              />
                            </div>
                          )}
                        </div>
                        <p
                          style={{
                            position: "absolute",
                            left: "100.5%",
                            bottom: "15px",
                          }}
                        >
                          or
                        </p>
                      </div>
                    </div>
                  </div>

                  <SimilarProducts
                    products={this.state.products}
                    similarProduct={this.state.similarProduct}
                    onChange={(val: any) => {
                      this.setState({ similarProduct: val });
                    }}
                  />
                  <CrossSellProducts
                    products={this.state.products}
                    crossSellProducts={this.state.crossSellProducts}
                    onChange={(val: any) => {
                      this.setState({ crossSellProducts: val });
                    }}
                  />
                  <div className="card">
                    <h3 style={{ paddingBottom: "20px" }}>Visibility</h3>
                    <div className="grid_2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <label>Featured</label>
                        <ToggleSwitch
                          value={this.state.isFeatured}
                          onClick={() => {
                            this.setState({
                              isFeatured: !this.state.isFeatured,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <label>Best Seller</label>
                        <ToggleSwitch
                          value={this.state.isBestSeller}
                          onClick={() => {
                            this.setState({
                              isBestSeller: !this.state.isBestSeller,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <label>New Launch</label>
                        <ToggleSwitch
                          value={this.state.newLaunch}
                          onClick={() => {
                            this.setState({
                              newLaunch: !this.state.newLaunch,
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <label>Limited View</label>
                        <ToggleSwitch
                          value={this.state.limitedview}
                          onClick={() => {
                            this.setState({
                              limitedview: !this.state.limitedview,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <>
                    <div className={`${styles.field_container}`}>
                      {this.state.advPricing ? (
                        <h3>
                          Adv. Pricing{" "}
                          <span
                            style={{
                              background: "#eff5fc",
                              color: "#36619b",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({
                                advPrice: [
                                  ...this.state.advPrice,
                                  {
                                    optionTitle: "",
                                    optionSpec: "",
                                    optionValue: "",
                                  },
                                ],
                              });
                            }}
                          >
                            Add
                          </span>
                        </h3>
                      ) : (
                        <h3>Pricing</h3>
                      )}
                      <div className={styles.toggle}>
                        <ToggleSwitch
                          value={this.state.advPricing}
                          onClick={() =>
                            this.setState({
                              advPricing: !this.state.advPricing,
                            })
                          }
                          onHoverTxt="Advance pricing"
                        />
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {!this.state.advPricing && (
                          <InputField
                            type={"number"}
                            isLabel={true}
                            lable="Price"
                            value={this.state.pricing.price}
                            onChange={(e: any) => {
                              this.setState({
                                pricing: {
                                  ...this.state.pricing,
                                  price: e.target.value,
                                },
                              });
                            }}
                          />
                        )}
                        {!this.state.advPricing && (
                          <InputField
                            type={"number"}
                            isLabel={true}
                            lable="Compare at price"
                            value={this.state.pricing.comparePrice}
                            onChange={(e: any) => {
                              this.setState({
                                pricing: {
                                  ...this.state.pricing,
                                  comparePrice: e.target.value,
                                },
                              });
                            }}
                          />
                        )}
                      </div>

                      {this.state.advPricing && (
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          {this.state.advPrice?.map((val: any, index: any) => {
                            return (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr",
                                  gap: "20px",
                                  marginTop: "10px",
                                  border: "1px solid lightgrey",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  position: "relative",
                                }}
                                className={styles.hidden_input_arrows}
                              >
                                <FontAwesomeIcon
                                  icon={faClose}
                                  color="rgb(54, 97, 155)"
                                  style={{
                                    position: "absolute",
                                    background: "rgb(239, 245, 252)",
                                    height: "15px",
                                    width: "15px",
                                    borderRadius: "100%",
                                    padding: "5px",
                                    cursor: "pointer",
                                    right: 0,
                                    top: 0,
                                    transform: "translate(50%,-50%)",
                                  }}
                                  onClick={() => {
                                    let temp = this.state.advPrice;
                                    temp.splice(index, 1);
                                    this.setState({
                                      advPrice: [...this.state.advPrice],
                                    });
                                  }}
                                />
                                <InputField
                                  type={"text"}
                                  isLabel={true}
                                  lable="Option Title"
                                  value={val.optionTitle}
                                  onChange={(e: any) => {
                                    let temp = this.state.advPrice;

                                    // @ts-ignore
                                    temp[index] = {
                                      ...temp[index],
                                      optionTitle: e.target.value,
                                    };

                                    this.setState({
                                      advPrice: [...temp],
                                    });
                                  }}
                                />
                                <InputField
                                  type={"text"}
                                  isLabel={true}
                                  lable="Option Specification"
                                  value={val.optionSpec}
                                  onChange={(e: any) => {
                                    let temp = this.state.advPrice;

                                    // @ts-ignore
                                    temp[index] = {
                                      ...temp[index],
                                      optionSpec: e.target.value,
                                    };

                                    this.setState({
                                      advPrice: [...temp],
                                    });
                                  }}
                                />
                                <InputField
                                  type={"number"}
                                  isLabel={true}
                                  lable="Option Value"
                                  value={val.optionValue}
                                  onChange={(e: any) => {
                                    let temp = this.state.advPrice;

                                    // @ts-ignore
                                    temp[index] = {
                                      ...temp[index],
                                      optionValue: e.target.value,
                                    };

                                    this.setState({
                                      advPrice: [...temp],
                                    });
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: "29px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    // justifyContent: "center",
                                    // border: "1px dashed lightgrey",
                                    borderRadius: "15px",
                                    paddingInline: "20px",
                                  }}
                                >
                                  <input type="checkbox" id="update_globally" />{" "}
                                  <label htmlFor="update_globally">
                                    Update Globally
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          marginLeft: "5px",
                          marginTop: "15px",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.pricing.chargeTax}
                          style={{ fontSize: "2rem" }}
                          onChange={(event: any) =>
                            this.setState({
                              pricing: {
                                ...this.state.pricing,
                                chargeTax: event.target.checked,
                              },
                            })
                          }
                        />
                        <p style={{ fontSize: "16px" }}>
                          Charge tax on this product
                        </p>
                      </div>
                      {this.state.pricing.chargeTax && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "20px",
                            marginTop: "10px",
                          }}
                          className={styles.hidden_input_arrows}
                        >
                          <InputField
                            type={"number"}
                            isLabel={true}
                            lable="GST"
                            value={this.state.pricing.igst}
                            onChange={(e: any) => {
                              this.setState({
                                pricing: {
                                  ...this.state.pricing,
                                  igst: e.target.value,
                                },
                              });
                            }}
                            sideIcon={true}
                          />
                          <InputField
                            type={"number"}
                            isLabel={true}
                            lable="HSN/SAC"
                            value={this.state.hsnsac}
                            onChange={(e: any) => {
                              this.setState({
                                hsnsac: e.target.value,
                              });
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "5px",
                              marginTop: "15px",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ fontSize: "2rem" }}
                              checked={this.state.pricing.inclusiveOfGST}
                              onChange={(e: any) =>
                                this.setState({
                                  pricing: {
                                    ...this.state.pricing,
                                    inclusiveOfGST: e.target.checked,
                                  },
                                })
                              }
                            />
                            <label style={{ fontSize: "16px" }}>
                              Price inclusive of GST
                            </label>
                          </div>
                        </div>
                      )}

                      {!this.state.advPricing && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "5px",
                              marginTop: "15px",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={this.state.show_profit}
                              style={{ fontSize: "2rem" }}
                              onChange={(event: any) => {
                                this.setState({
                                  show_profit: event.target.checked,
                                });
                                if (!event.target.checked) {
                                  this.setState({
                                    profit: {
                                      typeName: "",
                                      value: "",
                                    },
                                  });
                                }
                              }}
                              id="pricing_checkbox_lable"
                            />
                            <label
                              htmlFor="pricing_checkbox_lable"
                              style={{ fontSize: "16px" }}
                            >
                              Track Profit on this product
                            </label>
                          </div>
                          {this.state.show_profit && (
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                gap: "20px",
                                marginTop: "10px",
                              }}
                              className={styles.hidden_input_arrows}
                            >
                              <InputField
                                type={"select"}
                                isLabel={true}
                                options={[
                                  { title: "Fixed", value: "fixed" },
                                  { title: "Percentage", value: "percentage" },
                                ]}
                                accessText="title"
                                accessValue="value"
                                lable="Type"
                                value={this.state.profit.typeName}
                                onChange={(e: any) => {
                                  this.setState({
                                    profit: {
                                      ...this.state.profit,
                                      typeName: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <InputField
                                type={"number"}
                                isLabel={true}
                                lable="Value"
                                value={this.state.profit.value}
                                onChange={(e: any) => {
                                  this.setState({
                                    profit: {
                                      ...this.state.profit,
                                      value: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          marginLeft: "5px",
                          marginTop: "15px",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.requestQuote}
                          style={{ fontSize: "2rem" }}
                          onChange={(event: any) => {
                            this.setState({
                              requestQuote: event.target.checked,
                            });
                          }}
                          id="request_quote_checkbox_lable"
                        />
                        <label
                          htmlFor="request_quote_checkbox_lable"
                          style={{ fontSize: "16px" }}
                        >
                          Allow user to request for quote/bulk order
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginLeft: "5px",
                          marginTop: "15px",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.isAvailable.cod}
                          style={{ fontSize: "2rem" }}
                          onChange={(event: any) => {
                            this.setState({
                              isAvailable: {
                                ...this.state.isAvailable,
                                cod: event.target.checked,
                              },
                            });
                          }}
                          id="cod_checkbox_lable"
                        />
                        <label
                          htmlFor="cod_checkbox_lable"
                          style={{ fontSize: "16px" }}
                        >
                          Allow Cash on Delivery
                        </label>
                      </div>
                    </div>

                    {/* for inventory  */}
                    <ProductInventoryCard
                      state={this.state}
                      setState={(val: any) => this.setState({ ...val })}
                    />
                  </>
                  <>
                    {/* for Shipping  */}
                    <div className={`${styles.field_container}`}>
                      <h3>Shipping</h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <InputField
                          type={"number"}
                          isLabel={true}
                          lable="Weight"
                          value={this.state.shipping.weight}
                          onChange={(e: any) =>
                            this.setState({
                              shipping: {
                                ...this.state.shipping,
                                weight: e.target.value,
                              },
                            })
                          }
                        />
                        <InputField
                          type={"select"}
                          isLabel={true}
                          lable="Unit"
                          options={[
                            { title: "Kilogram", value: "kg" },
                            { title: "Gram", value: "gm" },
                          ]}
                          accessText="title"
                          accessValue="value"
                          value={this.state.shipping.unit}
                          onChange={(e: any) =>
                            this.setState({
                              shipping: {
                                ...this.state.shipping,
                                unit: e.target.value,
                              },
                            })
                          }
                        />
                        <InputField
                          type={"number"}
                          isLabel={true}
                          lable="Cost per unit weight"
                          value={this.state.shipping.costPerUnitWeight}
                          onChange={(e: any) =>
                            this.setState({
                              shipping: {
                                ...this.state.shipping,
                                costPerUnitWeight: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>

                    {/* for specification */}
                    <Specifications
                      state={this.state}
                      handleAddSpec={this.handleAddSpec}
                      handleRemoveSpec={this.handleRemoveSpec}
                      handleSpecProperties={this.handleSpecProperties}
                    />
                  </>
                  <>
                    {/* for Variants  */}
                    <Variants
                      state={this.state}
                      handleAddVariant={this.handleAddVariant}
                      handleVariantName={this.handleVariantName}
                      createCombination={this.createCombination}
                      handleVariantImageAdd={this.handleVariantImageAdd}
                      handleVariantImageRemove={this.handleVariantImageRemove}
                      handleRemoveVariant={this.handleRemoveVariant}
                      handleVariantImageName={this.handleVariantImageName}
                      setState={(updatedState: any) =>
                        this.setState({ ...updatedState })
                      }
                      handleProductAddInVariant={this.handleProductAddInVariant}
                    />
                    <div className={`${styles.field_container}`}>
                      <h3>Search Engine Listing</h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <InputField
                          type={"text"}
                          isLabel={true}
                          lable="Title"
                          value={this.state.seTitle}
                          onChange={(e: any) =>
                            this.setState({
                              seTitle: e.target.value,
                            })
                          }
                        />
                        <div></div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr",
                          gap: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <InputField
                          type={"text"}
                          isLabel={true}
                          lable='Route Handle (use "-" instead of space and punctuation are not allowed) (This cannot be edited once created.)'
                          value={this.state.route}
                          onChange={(e: any) => {
                            if (this.state.canChangeSlug) {
                              this.setState({
                                route: makeSlug(e.target.value),
                              });
                            }
                          }}
                        />
                      </div>
                      <div className={styles.text_area}>
                        <p>Meta Description</p>
                        <textarea
                          value={this.state.meta}
                          onChange={(e: any) =>
                            this.setState({ meta: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="card">
                      <h3>Other</h3>
                      <div className="grid" style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <label>is Returnable</label>
                          <ToggleSwitch
                            value={this.state.isReturnable}
                            onClick={() => {
                              this.setState({
                                isReturnable: !this.state.isReturnable,
                              });
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <label>is Exchangeable</label>
                          <ToggleSwitch
                            value={this.state.isExchangeable}
                            onClick={() => {
                              this.setState({
                                isExchangeable: !this.state.isExchangeable,
                              });
                            }}
                          />
                        </div>
                        <InputField
                          type="number"
                          isLabel={true}
                          lable={"Exchange Constant"}
                          value={this.state.exchangeConstant || ""}
                          onChange={(e: any) =>
                            this.setState({ exchangeConstant: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </>
                </div>

                <EnhancedProductContent
                  value={this.state.epc}
                  onChange={(val: any) => this.setState({ epc: val })}
                />
              </div>
            </ContentBody>
          </Content>
        )}
        {this.state.modal && (
          <div className={styles.modal_overlay}>
            <div className={styles.modal_container}>
              <FontAwesomeIcon
                icon={faClose}
                color="#36619B"
                onClick={() => this.setState({ modal: false })}
              />
              <div className={styles.image_preview}>
                <div
                  className={styles.image}
                  style={{
                    background: `url('${
                      this.state.combined.length !== 0
                        ? this.state.combined[this.state.preview_selected] // @ts-ignore
                            .design.imageUrl
                        : ""
                    }')`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src={
                      this.state.combined.length !== 0
                        ? // @ts-ignore
                          this.state.combined[this.state.preview_selected].style
                            .imageUrl
                        : ""
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.image_list}>
                <ul>
                  {this.state.combined?.map((val: any, index: any) => {
                    console.log(val);
                    return (
                      <li
                        key={index + "_combined_options"}
                        style={
                          this.state.preview_selected === index
                            ? { background: "#e2eefd" }
                            : {}
                        }
                        onClick={() =>
                          this.setState({ preview_selected: index })
                        }
                      >
                        {val.style_name} + {val.design_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(AddUpdateProduct);
