import React, { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../Components/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import { getFileName } from "../../Utils/getFileName";
import axios from "axios";
import toast from "react-hot-toast";
import { useAdminContext } from "../../Store/adminContext";
import heic2any from "heic2any";

const AddUpdateSubCategory = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [mediaUrl, setMediaUrl]: any = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const { admin }: any = useAdminContext();

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getData();
    }
  }, []);

  const getData = () => {
    axios({
      url:
        "https://backend.cftcommerce.com/api/productsubcategory/" + params?.id,
      method: "get",
    })
      .then((res: any) => {
        const v: any = res.data.productSubCategory;

        console.log(v);

        setTitle(v?.title);
        setDescription(v?.description);
        if (v?.media.length) {
          setMediaUrl([{ obj: null, url: v?.media[0].mediaUrl }]);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const uploadImage = async () => {
    if (mediaUrl.length === 0) {
      return [];
    }
    if (mediaUrl[0].obj !== null) {
      const filename = getFileName(mediaUrl[0].obj);
      await UploadImage({
        file: mediaUrl[0].obj,
        name: filename,
      });
      return [{ mediaUrl: filename, position: -1 }];
    } else {
      return [{ mediaUrl: mediaUrl[0].url, position: -1 }];
    }
  };

  const handleCreateAndUpdate = async () => {
    setLoading(true);

    let body: any = {
      identifier: localStorage.getItem("identifier"),
      title,
      description,
      visibility: true,
    };

    body.media = await uploadImage();

    console.log(body);

    axios({
      method: params?.id ? "PATCH" : "POST",
      url: `https://backend.cftcommerce.com/api/productsubcategory${
        params?.id ? "/" + params.id : "/"
      }`,
      data: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        navigate("/sub-categories");
        toast.success("Category Added Successfully.");
      })
      .catch((err: any) => {
        toast.error("Something went wrong, while adding a category");
      });
  };

  const handleAddImage = async (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      setMediaUrl([
        { obj: convertedFile, url: URL.createObjectURL(convertedFile) },
      ]);
    } else
      setMediaUrl([
        { obj: e.target.files[0], url: URL.createObjectURL(e.target.files[0]) },
      ]);
  };

  return (
    <Content>
      <ContentHead
        title={params?.id ? "Update Sub-Category" : "Add Sub-Category"}
        showBtn1={isUserAllowedToEdit(admin)}
        btn1type={"filled"}
        btn1Text={"Save"}
        btn1Name={"btn1"}
        showBtn2={isUserAllowedToEdit(admin)}
        btn2Text={"Save as Draft"}
        btn2type="outlined"
        btn2Name="btn2"
        showBtn3={true}
        btn3type={"outlined_del"}
        btn3Text={"Cancel"}
        btn3Name={"btn3"}
        onClickHandler={(e: any) => {
          if (e.target.name === "btn1") {
            handleCreateAndUpdate();
          } else if (e.target.name === "btn2") {
            handleCreateAndUpdate();
          } else if (e.target.name === "btn3") {
            navigate("/sub-categories");
          }
        }}
      />
      <ContentBody>
        <div className="card grid">
          <div className="grid_2">
            <InputField
              type="text"
              isLabel={true}
              lable={"Sub-Category Name"}
              value={title}
              onChange={(e: any) => setTitle(e.target.value.toLowerCase())}
            />
          </div>
          {mediaUrl.length === 0 && (
            <div
              className="media_container"
              // onClick={() => {
              //   image_for = "main";
              //   setOpen(true);
              // }}
            >
              <input
                type="file"
                accept=".heic,image/*"
                onChange={handleAddImage}
              />
              <p>+</p>
              <p>Add Media</p>
            </div>
          )}

          {mediaUrl.length !== 0 && (
            <ul className="selected_media_container">
              {mediaUrl?.map((val: any, index: any) => {
                return (
                  <div key={index} className="media_content">
                    <img
                      src={
                        val.obj !== null
                          ? val.url
                          : `${process.env.REACT_APP_IMAGE}${folder_name()}${
                              val.url
                            }`
                      }
                      alt={""}
                    />
                    <FontAwesomeIcon
                      icon={faClose}
                      color={"#36619b"}
                      onClick={() => {
                        setMediaUrl([]);
                      }}
                    />
                  </div>
                );
              })}
            </ul>
          )}
          <InputField
            type="textarea"
            isLabel={true}
            lable={"Description"}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </div>
      </ContentBody>
    </Content>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

export default AddUpdateSubCategory;
