import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { SideBarContext } from "../../Store/sideBar";

import styles from "../../Styles/applayout.module.css";

import { ReactComponent as Logo } from "../../Assets/icons/logo.svg";

import SingleSideElement from "./SingleSideElement";

import { ReactComponent as SettingsIcon } from "../../Assets/icons/setting.svg";
import { ReactComponent as Logout } from "../../Assets/icons/logout.svg";
import { ReactComponent as StoreIcon } from "../../Assets/icons/shop.svg";
import { ReactComponent as ProfileIcon } from "../../Assets/icons/profile.svg";
import { useLangContext } from "../../Store/langContext";
import langObj from "../../Utils/lang";
import { useAdminContext } from "../../Store/adminContext";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const context: any = useContext(SideBarContext);
  const { lang }: any = useLangContext();
  const { admin }: any = useAdminContext();

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      console.log(
        "e.target.className : ",
        typeof e.target.className,
        e.target.className
      );
      if (e.target.className && typeof e.target.className === "string") {
        if (
          e.target?.className.includes("menu_line") ||
          e.target?.className.includes("sidebar_main") ||
          e.target?.className.includes("menu_items_container") ||
          e.target?.className.includes("menu_icon_container")
        ) {
          console.log(true);
        } else {
          setOpen(false);
        }
      }
    });
  }, []);

  return (
    <div className={styles.sidebar_container}>
      <div
        className={styles.sidebar_main}
        style={{ width: open ? "200px" : "" }}
      >
        <div className={styles.sidebar_logo} style={{ paddingInline: "10px" }}>
          <img src="/logo.png" />
        </div>
        {/* menu Items */}
        <ul className={styles.menu_items_container}>
          {context.sidebar.map((val: any, index: any) => {
            return (
              <SingleSideElement
                val={val}
                key={index}
                location={location}
                navigate={navigate}
              />
            );
          })}
        </ul>
        <ul className={styles.bottom_items_container}>
          {/* Store */}
          {(admin.role === "admin" || admin.role === "superAdmin") &&
            localStorage.getItem("ecommerce") === "true" && (
              <li
                className={`${styles.bottom_item} ${
                  location.includes("stores") && styles.bottom_item_active
                }`}
                onClick={() => navigate("/stores")}
              >
                <StoreIcon />
                <span
                  className={`${styles.sidebar_text} ${
                    true && styles.sidebar_text_show
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  {langObj["Location"][lang]}
                </span>
              </li>
            )}
          {admin.role === "subadmin" &&
            (admin.store_access.read ||
              admin.store_access.write ||
              admin.store_access.delete) &&
            localStorage.getItem("ecommerce") === "true" && (
              <li
                className={`${styles.bottom_item} ${
                  location.includes("stores") && styles.bottom_item_active
                }`}
                onClick={() => navigate("/stores")}
              >
                <StoreIcon />
                <span
                  className={`${styles.sidebar_text} ${
                    true && styles.sidebar_text_show
                  }`}
                  style={{ textTransform: "capitalize" }}
                >
                  {langObj["Location"][lang]}
                </span>
              </li>
            )}
          {/* Settings */}
          <li
            className={`${styles.bottom_item} ${
              location.includes("settings") && styles.bottom_item_active
            }`}
            onClick={() => navigate("/settings")}
          >
            <SettingsIcon />
            <span
              className={`${styles.sidebar_text} ${
                true && styles.sidebar_text_show
              }`}
              style={{ textTransform: "capitalize" }}
            >
              {langObj["settings"][lang]}
            </span>
          </li>
          {/* Logout */}
          <li
            className={styles.bottom_item}
            onClick={() => {
              localStorage.removeItem("token-BO-ADMIN");
              localStorage.removeItem("blog");
              localStorage.removeItem("contact");
              localStorage.removeItem("career");
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }}
          >
            <Logout />
            <span
              className={`${styles.sidebar_text} ${
                true && styles.sidebar_text_show
              }`}
              style={{ textTransform: "capitalize" }}
            >
              {langObj["logout"][lang]}
            </span>
          </li>
        </ul>
      </div>

      <div className={styles.sidebar_main_mob}>
        <div className={styles.mob_logo}>
          <img src="/logo.webp" style={{ height: "45px" }} />
        </div>
        <div
          className={`${styles.menu_mob_btn} ${
            open ? styles.open_menu_icon : ""
          }`}
          onClick={() => {
            setOpen((val) => !val);
          }}
        >
          <div className={styles.menu_line_one}></div>
          <div className={styles.menu_line_two}></div>
          <div className={styles.menu_line_three}></div>
        </div>
        <div
          className={`${styles.mub_overlay} ${
            open ? styles.open_menu_mob : ""
          }`}
        >
          {open && (
            <ul className={styles.menu_items_container_mob}>
              {context.sidebar.map((val: any, index: any) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      if (!val?.child) {
                        navigate(val.path);
                      }
                    }}
                    className={`${
                      val.path === location ? styles.selected : ""
                    }`}
                  >
                    {open && <FontAwesomeIcon icon={val.icon} fill="green" />}
                    <span
                      className={`${styles.sidebar_text} ${
                        open && styles.sidebar_text_show
                      }`}
                    >
                      {val.title}
                    </span>
                  </li>
                );
              })}

              <li
                onClick={() => {
                  localStorage.removeItem("token-BO-ADMIN");
                  localStorage.removeItem("blog");
                  localStorage.removeItem("contact");
                  localStorage.removeItem("career");
                  navigate("/");
                  window.location.reload();
                }}
              >
                {open && <FontAwesomeIcon icon={faRightFromBracket} />}
                <span
                  className={`${styles.sidebar_text} ${
                    open && styles.sidebar_text_show
                  }`}
                >
                  Logout
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
