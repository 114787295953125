import { useEffect, useRef, useState } from "react";
import styles from "../../Pages/Leads/leads.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SingleRow = ({
  data,
  changeSelect,
  disableForAction,
  updatedContacts,
  setUpdatedContacts,
  subAdmins,
  onRowClick,
}: any) => {
  console.log("single row ::: ", data);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editOwner, setEditOwner] = useState(false);
  const [ownerList, setOwnerList] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [statusList, setStatusList] = useState(false);

  const [ownerSearch, setOwnerSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const emailInputRef: any = useRef(null);
  const phoneInputRef: any = useRef(null);

  // useEffect(() => {
  //   setEmail(data?.personalEmail || "");
  //   setPhone(data?.mobileNumber || "");
  // }, [data]);

  // useEffect(() => {
  //   if (updatedContacts.length === 0) {
  //     setEditEmail(false);
  //     setEditPhone(false);
  //   }
  // }, [updatedContacts]);

  // useEffect(() => {
  //   if (editEmail && emailInputRef.current) {
  //     emailInputRef?.current.focus();
  //   }
  // }, [editEmail]);

  // useEffect(() => {
  //   if (editPhone && phoneInputRef.current) {
  //     phoneInputRef.current.focus();
  //   }
  // }, [editPhone]);
  // console.log(data);
  console.log(data);
  return (
    <tr onClick={onRowClick}>
      <td>{data.orderRefId}</td>
      <td>{data.shippingAddress?.name || "--"}</td>
      <td>{data?.total || "--"}</td>
      <td>{data?.date}</td>
      <td>{data?.modeOfPayment}</td>

      <td>{data.paymentStatus || "--"}</td>
      <td>{data.fulfilmentStatus || "--"}</td>
      {/* <td>{"--"}</td>
      <td>--</td> */}
    </tr>
  );
};

export default SingleRow;
