import * as XLSX from "xlsx";
import Papa from "papaparse";
export const convert = (file: any): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one we want to process
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet to an array of arrays, skipping the first row
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Use the second row as headers
        const keys: any = jsonData[1];
        const values: any = jsonData.slice(2); // Get rows starting from the third row

        let result = [];
        let emptyRowCount = 0;

        for (let row of values) {
          // Check if the entire row is empty
          const isEmptyRow = row.every(
            (cell: any) => cell === undefined || cell === null || cell === ""
          );

          if (isEmptyRow) {
            emptyRowCount++;
            // If we encounter 5 consecutive empty rows, stop processing
            if (emptyRowCount >= 5) {
              break;
            }
          } else {
            emptyRowCount = 0; // Reset counter if a non-empty row is found
            // Convert the row into an object with keys
            const obj = keys.reduce((acc: any, key: any, i: number) => {
              acc[key] = row[i];
              return acc;
            }, {});
            result.push(obj);
          }
        }

        resolve(result); // Send the parsed data back to the component
      } catch (error) {
        reject(error); // Handle any errors
      }
    };

    reader.onerror = (error) => reject(error); // Handle file read errors

    reader.readAsArrayBuffer(file);
  });
};

export const parseCSVOrTSV = (
  file: File,
  encoding: any,
  delimiter: any,
  tsvDelimiter: any
) => {
  return new Promise((resolve, reject) => {
    // Determine if the file is CSV or TSV based on file extension or other criteria
    const isTSV = file.name.endsWith(".tsv") || file.name.endsWith(".tab");
    const chosenDelimiter = isTSV ? tsvDelimiter : delimiter;

    Papa.parse(file, {
      encoding,
      delimiter:
        chosenDelimiter === "comma"
          ? ","
          : chosenDelimiter === "semi-colon"
          ? ";"
          : chosenDelimiter === "tab"
          ? "\t"
          : "\t", // Default to tab if no valid delimiter is found
      complete: (result: any) => {
        const data = result.data;

        // If the data length is greater than 1 (meaning there's more than just the header)
        if (data.length > 1) {
          const keys = data[1]; // Second row as keys
          const values = data.slice(2); // Remaining rows as values

          // Initialize a counter for empty rows
          let emptyRowCount = 0;
          const formattedData = [];

          for (let i = 0; i < values.length; i++) {
            const row = values[i];

            // Check if the row is empty
            if (row.every((cell: string) => cell === "")) {
              emptyRowCount++;
              // Stop reading if 5 consecutive empty rows are found
              if (emptyRowCount >= 5) break;
              continue;
            }

            // Reset empty row count if a non-empty row is found
            emptyRowCount = 0;

            const obj = keys.reduce((acc: any, key: string, index: number) => {
              acc[key] = row[index] || ""; // Assign empty string if the value is undefined
              return acc;
            }, {});

            formattedData.push(obj);
          }

          resolve(formattedData);
        } else {
          reject("File does not have enough data.");
        }
      },
      error: (error: any) => reject(error),
    });
  });
};

export const parseExcel = (file: File, encoding: string) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, {
        type: "array",
        codepage: encodingMap[encoding] || 65001,
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (jsonData.length > 1) {
        const keys: any = jsonData[1]; // Second row as keys
        const values: any = jsonData.slice(2); // Rows after the second
        const formattedData: any[] = [];
        let emptyRowCount = 0;

        for (const row of values) {
          if (row.every((cell: any) => !cell)) {
            // Check if the row is empty
            emptyRowCount++;
            if (emptyRowCount >= 5) break; // Stop processing after 5 empty rows
          } else {
            emptyRowCount = 0;
            const obj = keys.reduce((acc: any, key: string, index: number) => {
              acc[key] = row[index];
              return acc;
            }, {});
            formattedData.push(obj);
          }
        }

        if (formattedData.length > 0) {
          resolve(formattedData);
        } else {
          reject("No valid data found.");
        }
      } else {
        reject("File does not have enough data.");
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const encodingMap: { [key: string]: number } = {
  "utf-8": 65001,
  "utf-16": 1200,
  "iso-8859-1": 28591,
  "iso-8859-2": 28592,
  "iso-8859-9": 28599,
  gb2312: 936,
  big5: 950,
  "shift-jis": 932,
};
