import { useEffect, useLayoutEffect, useState } from "react";
import styles from "../../Styles/products.module.css";
import InputField from "../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export const ProductInventoryCard = ({ state, setState }: any) => {
  const [quantity, setQuantity] = useState("");

  const [hasSku, setHasSku] = useState(false);

  const [inventory_safety_stock, setInventorySafteyStock] = useState(false);

  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    setInventorySafteyStock(state.inventory?.safetyStock ? true : false);
    setHasSku(state.inventory?.sku || state.inventory?.barcode ? true : false);
  }, []);

  return (
    <div className={`${styles.field_container}`}>
      <div className="card_head">
        <h3>Inventory</h3>
        {state.inventory.trackQuantity && (
          <p>Current Quantity: {state.inventory.currentQuantity || 0}</p>
        )}
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "5px",
          marginTop: "15px",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <input
          type="checkbox"
          checked={state.inventory.trackQuantity}
          style={{ fontSize: "2rem" }}
          onChange={(e) =>
            setState({
              inventory: {
                ...state.inventory,
                trackQuantity: e.target.checked,
              },
            })
          }
        />
        <p style={{ fontSize: ".9rem" }}>Track Quantity of this product</p>
      </div>

      {state.inventory.trackQuantity && (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              marginTop: "10px",
              position: "relative",
            }}
          >
            {isAdded ? (
              <div
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#eff5fc",
                  color: "#36619b",
                  paddingInline: "20px",
                  borderRadius: "10px",
                }}
              >
                <p>Added Quantity : {quantity}</p>
                <FontAwesomeIcon
                  icon={faClose}
                  color="#36619b"
                  style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  onClick={() => {
                    setIsAdded(false);
                    setQuantity("");
                    let temp = [...state.inventory.addQuantity];
                    temp.shift();
                    setState({
                      inventory: { ...state.inventory, addQuantity: temp },
                    });
                  }}
                />
              </div>
            ) : (
              <>
                <InputField
                  type={"number"}
                  isLabel={true}
                  lable="Quantity"
                  value={quantity}
                  onChange={(e: any) => setQuantity(e.target.value)}
                />

                <button
                  style={{
                    position: "absolute",
                    height: "50px",
                    left: "50%",
                    bottom: "0",
                    width: "100px",
                    borderRadius: "15px",
                    border: "none",
                    fontSize: ".9rem",
                    background: "#eff5fc",
                    color: "#36619b",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let new_one = [...state.inventory.addQuantity];
                    new_one.unshift({
                      value: quantity,
                      date: new Date().toString(),
                    });

                    setState({
                      inventory: {
                        ...state.inventory,
                        addQuantity: new_one,
                        currentQuantity: state.inventory.currentQuantity
                          ? Number(state.inventory.currentQuantity) +
                            Number(quantity)
                          : quantity,
                      },
                    });

                    setIsAdded(true);
                  }}
                >
                  Add
                </button>
              </>
            )}
          </div>
          {true && (
            <>
              <div
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  marginTop: "15px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <input
                  type="checkbox"
                  checked={state.inventory.sellOutstock}
                  onChange={(e) =>
                    setState({
                      inventory: {
                        ...state.inventory,
                        sellOutstock: e.target.checked,
                      },
                    })
                  }
                  style={{ fontSize: "2rem" }}
                />
                <p style={{ fontSize: ".9rem" }}>
                  Continue selling when out of stock
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  marginTop: "15px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <input
                  type="checkbox"
                  checked={inventory_safety_stock}
                  onChange={(e) => {
                    setInventorySafteyStock(e.target.checked);
                    if (!e.target.checked) {
                      setState({
                        inventory: {
                          ...state.inventory,
                          safetyStock: "",
                        },
                      });
                    }
                  }}
                  style={{ fontSize: "2rem" }}
                />
                <p style={{ fontSize: ".9rem" }}>Safety Stock </p>
              </div>
              {inventory_safety_stock && (
                <>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <InputField
                      type={"number"}
                      isLabel={true}
                      lable="Safety Stock"
                      value={state.inventory.safetyStock}
                      onChange={(e: any) =>
                        setState({
                          inventory: {
                            ...state.inventory,
                            safetyStock: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      <div
        style={{
          display: "flex",
          marginLeft: "5px",
          marginTop: "15px",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <input
          type="checkbox"
          checked={hasSku}
          onChange={(e) => setHasSku(e.target.checked)}
          style={{ fontSize: "2rem" }}
        />
        <p style={{ fontSize: ".9rem" }}>This Product has a SKU or Barcode</p>
      </div>
      {hasSku && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <InputField
            type={"string"}
            isLabel={true}
            lable="SKU (Stock Keeping Unit)"
            value={state.inventory.sku}
            onChange={(e: any) =>
              setState({
                inventory: {
                  ...state.inventory,
                  sku: e.target.value,
                },
              })
            }
          />
          <InputField
            type={"number"}
            isLabel={true}
            lable="Barcode ( ISBN, UPC, GTIN, etc.)"
            value={state.inventory.barcode}
            onChange={(e: any) =>
              setState({
                inventory: {
                  ...state.inventory,
                  barcode: e.target.value,
                },
              })
            }
          />
        </div>
      )}
    </div>
  );
};
