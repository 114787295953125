import axios from "axios";
import React, { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { product_body_keys } from "../../Utils/Data";
import withRouter from "../../Components/withRouter";
import { toast } from "react-hot-toast";
import Pagination from "../../Components/Table/Pagination";
import Modal from "../../Components/Modal";
import DropBtn from "../../Components/DropBtn";
import { faPlus, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCategoryList } from "../../Apis/filterApi";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "../../Utils/getQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAdminContext } from "../../Store/adminContext";

type IState = {
  data: any[];
  loading: boolean;
  page: number;
  total: number;
  modal: boolean;
  inFocus: string;
};

let inFocus = "";

const Products = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [searched, setSearched] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { admin }: any = useAdminContext();

  const location = useLocation();

  const navigate = useNavigate();
  const query_data: string = useQuery();

  useEffect(() => {
    getData();
  }, [location.search]);

  const handlePage = () => {
    let search = new URLSearchParams(location.search);
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url:
        "https://backend.cftcommerce.com/api/products/getallproductsforadmin?identifier=" +
        localStorage.getItem("identifier") +
        `&limit=${Number(search.get("limit")) || 15}&${query_data}`,
      method: "",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(
          res.data.products.map((v: any) => ({
            ...v,
            node: v?.isVariant ? "Variant" : "Main",
          })),
        );
        setTotal(res.data.total);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: "https://backend.cftcommerce.com/api/products/" + inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            inFocus = "";
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      },
    );
  };

  useEffect(() => {
    getAllCategoryList()
      .then((result) => {
        console.log("Result:", result);
        setFilterData({
          Category: result.distinctCategory.filter((v: any) => v),
          Collection: result.distinctCollectionName.filter((v: any) => v),
        });
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors here
      });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSearchData();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searched]);

  const getSearchData = () => {
    let search = new URLSearchParams(location.search);
    if (searched !== "") {
      setLoading(true);
      axios({
        url: `https://backend.cftcommerce.com/api/products/search/${localStorage.getItem(
          "identifier",
        )}?text=${searched}&page=${Number(search.get("page")) || 1}&limit=${
          Number(search.get("limit")) || 15
        }`,
        method: "GET",
      })
        .then((res: any) => {
          // console.log(res);
          setData([...res.data.data]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error is : :: : ", err);
          setLoading(false);
        });
    } else {
      getData();
    }
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
        }}
        onBtn2Click={handleAction}
      />

      <WithTableView
        loading={loading}
        heading="Products"
        head={productHead(admin)}
        filtervalue={filterData}
        body={data}
        body_keys={productKeys(admin)}
        show_btn_1={false}
        isIcon={true}
        iconType="add"
        btn1Text="New Product"
        btn1Type="filled"
        page={Number(new URLSearchParams(location.search).get("page")) || 1}
        onClickHandler={() => {
          navigate("/products/add");
        }}
        onRowClick={(val: any) => {
          navigate(`/products/update/${val._id}`);
        }}
        onActionClick={(_: any, id: any) => {
          setModal(true);
          inFocus = id;
        }}
        onCloneClick={(val: any) => {
          navigate(`/products/clone/${val._id}`);
        }}
        // onMoreClick={
        //   setMoreModal(true)
        // }
        customElement={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                height: "37px",
                width: "300px",
                borderRadius: "10px",
                background: "white",
                overflow: "hidden",
                alignItems: "center",
                paddingLeft: "10px",
                border: "1px solid #ddd",
                marginRight: "10px",
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                style={{ height: "17px", color: "lightgrey" }}
              />
              <input
                placeholder="Search Products By Name, sku, ..."
                value={searched}
                onChange={(e: any) => setSearched(e.target.value)}
                style={{
                  height: "100%",
                  border: "none",
                  outline: "none",
                  fontSize: "0.9rem",
                  padding: "10px",
                  flex: 1,
                }}
              />
            </div>

            {Math.ceil(total / 15) >= 2 ? (
              <Pagination
                pageNo={
                  Number(new URLSearchParams(location.search).get("page")) || 1
                }
                changePage={(val: any) => {
                  let search = new URLSearchParams(location.search);
                  search.set("page", String(val));
                  navigate(`/products?${String(search)}`);
                }}
                total={total}
              />
            ) : (
              <></>
            )}

            {isUserAllowedToEdit(admin) && (
              <DropBtn
                text="New"
                isIcon={true}
                icon={faPlus}
                drop_list={[
                  {
                    title: "Add Single Product",
                    id: "add_single_product",
                    icon: faPlus,
                  },
                  {
                    title: "Bulk Upload Products",
                    id: "add_bulk_products",
                    icon: faUpload,
                  },
                ]}
                on_click={(id: any) => {
                  if (id === "add_single_product") {
                    navigate("/products/add");
                  } else if (id === "add_bulk_products") {
                    navigate("/products/upload");
                  }
                }}
              />
            )}
          </>
        }
      />
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const productHead = (admin: any) => {
  const base_head = [
    { title: "S.No.", filter: false },
    { title: "SKU", filter: false },
    { title: "Product Name", filter: false },
    { title: "Category", value: "Category", filter: true },
    { title: "Collection", value: "Collection", filter: true },
    { title: "Current Stock", filter: false },
    { title: "Price", filter: false },
    { title: "Node", filter: false },
    { title: "Created At", filter: false },
  ];

  if (admin.role !== "subadmin")
    return [...base_head, { title: "", filter: false }];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_head, { title: "", filter: false }];
    }
    if (admin.catalog_access.write) {
      return [...base_head, { title: "", filter: false }];
    }
  }

  return base_head;
};

const productKeys = (admin: any) => {
  const base_keys = [
    "inventory.sku",
    "title",
    "category",
    "collectionName",
    "inventory.currentQuantity",
    "pricing.price",
    "node",
    "createdAt",
  ];

  if (admin.role !== "subadmin") return [...base_keys, "action:more"];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_keys, "action:more"];
    }
    if (admin.catalog_access.write) {
      console.log("sending this list back");
      return [...base_keys, "action:more-clone"];
    }
  }

  return base_keys;
};

export default withRouter(Products);
