const langObj: any = {
  content: {
    en: "content",
    hn: "सामग्री",
  },
  contacts: { en: "contacts", hn: "contacts" },
  order: {
    en: "orders",
    hn: "ऑर्डर",
  },
  sales: {
    en: "sales",
    hn: "बिक्री",
  },
  cart: {
    en: "carts",
    hn: "कार्ट",
  },
  abandonedCarts: { en: "Abandoned Carts", hn: "परित्यक्त कार्ट" },
  catalog: { en: "catalog", hn: "सूची" },
  productVideo: { en: "product video", hn: "उत्पाद वीडियो" },
  product: { en: "products", hn: "उत्पाद" },
  categories: { en: "categories", hn: "श्रेणियाँ" },
  subCategories: { en: "Sub Categories", hn: "उपश्रेणी" },
  collection: { en: "collections", hn: "संग्रह" },
  blog: { en: "blogs", hn: "ब्लॉग" },
  blogHeading: { en: "Blog Heading", hn: "ब्लॉग शीर्षक" },
  "Blog Title": { en: "Blog Title", hn: "ब्लॉग का शीर्षक" },
  "New Blog": { en: "New Blog", hn: "नया ब्लॉग" },
  "Add New Blog": { en: "Add new Blog", hn: "नया ब्लॉग जोड़ें" },
  updateBlog: { en: "Update Blog", hn: "ब्लॉग अपडेट करें" },
  editBlog: { en: "Edit Blog", hn: "ब्लॉग संपादित करें" },
  deleting: { en: "Deleting...", hn: "हटाने..." },
  deletedSuccessfully: {
    en: "Deleted Successfully",
    hn: "सफलतापूर्वक मिटाया गया",
  },
  errorWhileDeleting: {
    en: "Error While Deleting",
    hn: "हटाते समय त्रुटि",
  },
  blogContent: { en: "Blog Content", hn: "ब्लॉग सामग्री" },
  shortDescription: { en: "Short Description", hn: "संक्षिप्त वर्णन" },
  DoYouReallyWantToDeleteThisRecordThisProcessCannotBeUndone: {
    en: "Do you really want to delete this record. This process cannot be undone",
    hn: "क्या आप वास्तव में इस रिकॉर्ड को हटाना चाहते हैं? इस प्रक्रिया को पूर्ववत नहीं किया जा सकता",
  },
  delete: { en: "Delete", hn: "हटाएँ" },
  deleteImage: { en: "Delete Image", hn: "छवि हटाएँ" },
  discount: { en: "discount", hn: "छूट" },
  commaSeperatedTags: {
    en: "Comma Seperated Tags",
    hn: "अल्पविराम से अलग टैग",
  },
  uploadAnImageOfUpto5MB: {
    en: "Upload an Image of upto 5mb",
    hn: "5 एमबी तक की एक छवि अपलोड करें",
  },
  author: { en: "Author", hn: "लेखक" },
  authorName: { en: "Author Name", hn: "लेखक का नाम" },
  Name: { en: "Name", hn: "नाम" },
  save: { en: "Save", hn: "सेव करें" },
  selectYourOption: { en: "Select Your Option", hn: "अपना विकल्प चुनें" },
  saveAsDraft: { en: "Save as Draft", hn: "ड्राफ्ट के रूप में सेव करें" },
  user: { en: "users", hn: "ग्राहक" },
  compliance: { en: "compliance", hn: "नीतियाँ" },
  caseStudy: { en: "Case Study", hn: "अध्ययन" },
  caseStudyCreatedSuccessfully: {
    en: "Case Study Created Successfully",
    hn: "केस स्टडी सफलतापूर्वक बनाई गई",
  },
  caseStudyUpdatedSuccessfully: {
    en: "Case Study Updated Successfully",
    hn: "केस स्टडी सफलतापूर्वक अपडेट की गई",
  },
  updateCaseStudy: {
    en: "Update Case Study",
    hn: "अपडेट केस स्टडी",
  },
  newCaseStudy: {
    en: "New Case Study",
    hn: "केस स्टडी जोड़ें",
  },
  addNewCaseStudy: {
    en: "Add New Case Study",
    hn: "नई केस स्टडी जोड़ें",
  },
  commaSeparatedTags: {
    en: "Comma Seperated Tags",
    hn: "अल्पविराम से अलग किए गए टैग",
  },
  cancel: { en: "Cancel", hn: "रद्द करें" },
  case: { en: "case", hn: "अध्ययन" },
  banner: { en: "Banner", hn: "बैनर" },
  bannerImage: { en: "Banner Image", hn: "बैनरछवि" },
  lead: { en: "leads", hn: "संभावित ग्राहक" },
  service: { en: "services", hn: "सेवा" },
  analytics: { en: "analytics", hn: "एनालिटिक्स" },
  overview: { en: "Overview", hn: "Overview" },
  job: { en: "jobs", hn: "नौकरी" },
  applicants: { en: "applicants", hn: "आवेदक" },
  settings: { en: "settings", hn: "सेटिंग" },
  logout: { en: "logout", hn: "लॉग आउट" },
  firstName: { en: "first name", hn: "पहला नाम" },
  "First Name": { en: "First Name", hn: "पहला नाम" },
  lastName: { en: "last name", hn: "उपनाम" },
  "Last Name": { en: "Last Name", hn: "उपनाम" },
  "S.No.": { en: "S.No.", hn: "क्र.सं." },
  "Phone no.": { en: "Phone No.", hn: "फोन नंबर" },
  Email: { en: "Email", hn: "ईमेल" },
  Remarks: { en: "Remarks", hn: "टिप्पणी" },
  Status: { en: "Status", hn: "स्थिति" },
  export: { en: "Export", hn: "निर्यात" },
  excel: { en: "excel", hn: "एक्सेल" },
  exportExcel: { en: "Export Excel", hn: "एक्सेल निर्यात करें" },
  body: { en: "Body", hn: "शरीर" },
  SKU: { en: "SKU", hn: "SKU" },
  "Booking Date": { en: "Booking Date", hn: "Booking Date" },
  productName: { en: "product name", hn: "उत्पाद का नाम" },
  "Product Name": { en: "Product Name", hn: "उत्पाद का नाम" },
  currentStock: { en: "current stock", hn: "वर्तमान मात्रा" },
  "Current Stock": { en: "Current Stock", hn: "वर्तमान मात्रा" },
  price: { en: "price", hn: "कीमत" },
  createdAt: { en: "created at", hn: "जोड़ा गया" },
  "Created At": { en: "Created At", hn: "जोड़ा गया" },
  new: { en: "new", hn: "नया" },
  Title: { en: "title", hn: "शीर्षक" },
  category: { en: "category", hn: "श्रेणी" },
  Category: { en: "Category", hn: "श्रेणी" },
  subCategory: { en: "subcategory", hn: "उपश्रेणी" },
  collectionName: { en: "collection name", hn: "संग्रह का नाम" },
  Collection: { en: "Collection", hn: "संग्रह" },
  productType: { en: "product type", hn: "उत्पाद का प्रकार" },
  Type: { en: "Type", hn: "प्रकार" },
  tags: { en: "tags", hn: "टैग" },
  filled: { en: "Filled", hn: "भरा हुआ" },
  countryOfOrigin: { en: "country of origin", hn: "उत्पादन देश" },
  description: { en: "description", hn: "विवरण" },
  media: { en: "media", hn: "मिडिया" },
  acceptsImagesAndVideos: {
    en: "accepts images and videos",
    hn: "छवियाँ और वीडियो स्वीकार करता है",
  },
  document: { en: "document", hn: "दस्तावेज़" },
  documentType: { en: "document type", hn: "दस्तावेज़ का प्रकार" },
  documentUrlUpload: {
    en: "document url / upload",
    hn: "दस्तावेज़ यूआरएल/अपलोड करें",
  },
  uploadDocument: { en: "upload document", hn: "दस्तावेज़ अपलोड करें" },
  visibility: { en: "visibility", hn: "दृश्यता" },
  featured: { en: "featured", hn: "प्रदर्शित" },
  bestSeller: { en: "best seller", hn: "सर्वश्रेष्ठ उत्पाद" },
  newLaunch: { en: "new launch", hn: "नया उत्पाद" },
  limitedView: { en: "limited view", hn: "सीमित दृश्य" },
  similarProducts: { en: "similar products", hn: "समान उत्पाद" },
  selectProducts: { en: "select products", hn: "उत्पादों का चयन करें" },
  pricing: { en: "pricing", hn: "कीमत निर्धारण" },
  compareAtPrice: { en: "compare at price", hn: "तुलनीय कीमत" },
  Price: { en: "Price", hn: "कीमत" },
  chargeTaxOnThisProduct: {
    en: "Charge tax on this product",
    hn: "इस उत्पाद पर टैक्स वसूलें",
  },
  trackProfitOnThisProduct: {
    en: "Track Profit on this product",
    hn: "इस उत्पाद पर लाभ ट्रैक करें",
  },
  gst: { en: "gst", hn: "जीएसटी" },
  hsnsac: { en: "HSN/SAC", hn: "एचएसएन/एसएसी" },
  fixed: { en: "fixed", hn: "तय" },
  percentage: { en: "percentage", hn: "प्रतिशत" },
  "Order ID": { en: "Order ID", hn: "Order ID" },
  "Order Date": { en: "Order Date", hn: "Order Date" },
  "Mode Of Payment": { en: "Mode Of Payment", hn: "Mode Of Payment" },
  "Payment Status": { en: "Payment Status", hn: "Payment Status" },
  "Order Status": { en: "Order Status", hn: "Order Status" },
  type: { en: "type", hn: "प्रकार" },
  value: { en: "value", hn: "मूल्य" },
  "Customer Name": { en: "Customer Name", hn: "Customer Name" },
  allowUserToRequestForQuoteBulkOrder: {
    en: "Allow user to request for quote/bulk order",
    hn: "उपयोगकर्ता को कोटेशन/थोक ऑर्डर के लिए अनुरोध करने की अनुमति दें",
  },
  allowCashOnDelivery: {
    en: "Allow Cash on Delivery",
    hn: "कैश ऑन डिलीवरी की अनुमति दें",
  },
  trackQuantityOfThisProduct: {
    en: "Track Quantity of this product",
    hn: "इस उत्पाद की मात्रा ट्रैक करें",
  },
  thisProductHasSKUBarcode: {
    en: "This Product has a SKU or Barcode",
    hn: "इस उत्पाद में SKU या बारकोड है",
  },
  quantity: { en: "Quantity", hn: "मात्रा" },
  currentQuantity: { en: "Current Quantity", hn: "वर्तमान मात्रा" },
  add: { en: "add", hn: "जोड़ना" },
  continueSellingWhenOutOfStock: {
    en: "Continue selling when out of stock",
    hn: "स्टॉक ख़त्म होने पर बिक्री जारी रखें",
  },
  safetyStock: { en: "Safety Stock", hn: "सुरक्षा स्टॉक" },
  skuStockKeepingUnit: {
    en: "SKU (Stock Keeping Unit)",
    hn: "SKU (स्टॉक कीपिंग यूनिट)",
  },
  barcodeIsbnUpcGtinEtc: {
    en: "Barcode ( ISBN, UPC, GTIN, etc.)",
    hn: "बारकोड (आईएसबीएन, यूपीसी, जीटीआईएन, आदि)",
  },
  shipping: { en: "Shipping", hn: "शिपिंग" },
  weight: { en: "Weight", hn: "वज़न" },
  unit: { en: "Unit", hn: "इकाई" },
  kilogram: { en: "kilogram", hn: "किलोग्राम" },
  gram: { en: "gram", hn: "ग्राम" },
  costUnit: { en: "Cost per unit weight", hn: "लागत प्रति यूनिट वजन" },
  specifications: { en: "specifications", hn: "विशेष विवरण" },
  variants: { en: "variants", hn: "वेरिएंट" },
  color: { en: "color", hn: "रंग" },
  material: { en: "material", hn: "सामग्री" },
  sizeCapacity: { en: "size/capacity", hn: "आकार/क्षमता" },
  optionName: { en: "Option name", hn: "विकल्प का नाम" },
  optionValue: { en: "Option values", hn: "विकल्प का मूल्य" },
  pressEnterToAddValue: {
    en: "Press Enter to add value",
    hn: "का जोड़ने के लिए Enter दबाएँ",
  },
  routeHandle: { en: "Route Handle", hn: "रूट हैंडल" },
  routeWarning: {
    en: "use '-' instead of space and punctuation are not allowed) (This cannot be edited once created.",
    hn: "रिक्त स्थान के स्थान पर '-' का प्रयोग करें और विराम चिह्न की अनुमति नहीं है) (एक बार बन जाने के बाद इसे संपादित नहीं किया जा सकता।",
  },
  metaDescription: { en: "Meta Description", hn: "मेटा विवरण" },
  enhancedProductContent: {
    en: "Enhanced Product Content",
    hn: "उन्नत उत्पाद सामग्री",
  },
  onClickUrl: { en: "On Click Url", hn: "यूआरएल पर क्लिक करें" },
  Position: { en: "Position", hn: "पद" },
  saveAsVariant: { en: "save as variant", hn: "वैरिएंट सेव करें" },
  codPrice: { en: "COD Price", hn: "सीओडी कीमत" },
  store: { en: "Store", hn: "दुकान" },
  Department: { en: "Department", hn: "विभाग" },
  Action: { en: "Action", hn: "कार्रवाई" },
  "Applied On": { en: "Applied On", hn: "Applied On" },
  "Applied Position": { en: "Applied Position", hn: "Applied Positino" },
  "Application Status": { en: "Application Status", hn: "Application Status" },
  "": { en: "", hn: "" },
  Location: { en: "Location", hn: "Location" },
  "Sub Location": { en: "Sub Location", hn: "Sub Location" },
  "Sub Category": { en: "Sub Category", hn: "Sub Category" },
  "Discount Title": { en: "Discount Title", hn: "Discount Title" },
  "Discount Code": { en: "Discount Code", hn: "Discount Code" },
  "Video Title": { en: "Video Title", hn: "Video Title" },
  "Last Updated": { en: "Last Updated", hn: "Last Updated" },
  "Click Count": { en: "Click Count", hn: "Click Count" },
  Analytics: { en: "Analytics", hn: "Analytics" },
  profile: { en: "Profile", hn: "Profile" },
  "order value": { en: "Order Value", hn: "Order Value" },
  Node: { en: "Node", hn: "Node" },
  "shoppable videos": { en: "Shoppable Videos", hn: "Shoppable Videos" },
  "shoppable video": { en: "Shoppable Video", hn: "Shoppable Video" },
  "Service Title": { en: "Service Title", hn: "Service Title" },
};

export default langObj;
