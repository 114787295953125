import React, { forwardRef, useEffect, useLayoutEffect, useState } from "react";
import styles from "./thermalInvoice.module.css";
import { numberToWords } from "../../../Utils/numberToWordsConverter";
import { useReactToPrint } from "react-to-print";

const ThermalInvoice = forwardRef((props: any, ref: any) => {
  const [height, setheight] = useState<number>(0);
  const { user, order, admin }: any = props;
  console.log(order);
  console.log(admin);
  //  console.log(ref.current.offsetHeight)

  useLayoutEffect(() => {
    if (ref.current) {
      const invoice = ref.current;
      // Accessing the clientHeight after the component has mounted and layout has been calculated
      const heightInPixels = invoice.clientHeight;
      setheight(heightInPixels);
      //  console.log(`Height in pixels: ${heightInPixels}`);
    }
  }, [order]);

  // console.log(items)
  if (order.length === 0) {
    return <>Loading...</>;
  }

  const calculateTaxAmount = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ): string => {
    if (isInclusive) {
      return (subTotal - (subTotal / 1 + gst / 100)).toFixed(2);
    }
    // this will return the price after adding tax .
    //  console.log(Math.round(subTotal + (gst / 100) * subTotal));
    return ((gst / 100) * subTotal).toFixed(2);
  };

  const totalIGSTAmount = () => {
    const totaligst = order?.products?.reduce((acc: any, product: any) => {
      const taxAmount = calculateTaxAmount(
        product?.productId?.pricing?.price,
        product?.productId?.pricing?.inclusiveOfGST,
        product?.productId.pricing?.igst
      );
      const taxAmountWithQuantity = Number(taxAmount) * product.quantity;
      return acc + taxAmountWithQuantity;
    }, 0);
    return totaligst;
  };

  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    console.log(Math.round(subTotal + (gst / 100) * subTotal));
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  const heightInMM = (height / 95) * 22;
  //  console.log(height,heightInMM)

  return (
    <div className={styles.invoiceContainer} ref={ref} id="invoice">
      <style>{`@page { size:57mm ${heightInMM}mm;}`}</style>
      <h4>RETAIL INVOICE</h4>
      <h1 className={styles.title}>{admin.businessName}</h1>
      <div className={styles.header}>
        <div>
          {admin?.address?.addLineOne}, {admin?.address?.addLineTwo},{" "}
          {admin?.address?.city}
          {admin.address?.companyStateZip}
        </div>
        {/* <div>Ph No.: 9205594320</div> */}
        {/* <div>GSTIN : 07CQAP56343D1Z1</div> */}
      </div>
      <div className={styles.details_grid}>
        <div>
          Date: <span>{new Date().toLocaleDateString()}</span>
        </div>
        <div>
          Time: <span>{new Date().toLocaleTimeString()}</span>
        </div>
        <div>
          Bill No:{" "}
          <span>
            <strong>{order.orderRefId}</strong>
          </span>
        </div>
        <div>
          User: <span>ADMIN</span>
        </div>
      </div>

      <div className={styles.item_heading}>
        <span>Item Name</span>
        <span>Qty</span>
        <span>Rate</span>
        <span>GST %</span>
        <span>Amt</span>
      </div>

      <div className={styles.items}>
        {order?.products?.map((item: any, index: any) => (
          <div key={index} className={styles.item}>
            <div className={styles.itemTitle}>{item.productId?.title}</div>
            <div className={styles.itemDetails}>
              <span></span>
              <span>{item?.quantity || "NA"}</span>
              <span>
                {" "}
                ₹{" "}
                {item?.productId?.pricing?.inclusiveOfGST
                  ? calculateAmt(
                      item?.productId?.pricing?.price,
                      item?.productId?.pricing?.inclusiveOfGST,
                      item?.productId.pricing?.igst
                    )
                  : item?.productId?.pricing?.price}
              </span>
              <span> {item?.productId?.pricing?.igst}%</span>
              <span>
                {" "}
                ₹{" "}
                {item?.quantity *
                  Number(
                    calculateAmt(
                      item?.productId?.pricing?.price,
                      item?.productId?.pricing?.inclusiveOfGST,
                      item?.productId.pricing?.igst
                    )
                  )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.total}>
        <div>
          <h4> Sub Total:</h4>
          <h4> ₹{order.subTotal}</h4>
        </div>
        <div>
          <h4>GST: </h4>
          <h4>₹{totalIGSTAmount()}</h4>
        </div>
        <div>
          <h4>Total: </h4>
          <h4>₹{order.total}</h4>
        </div>
      </div>

      <div className={styles.footer}>
        <p className={styles.totalWords}>
          In Words: {numberToWords(Number(order.total))}
        </p>
        <p>Thank you, Please Visit Again</p>
        <div className={styles.user}>
          <p>{order.billingAddress?.name}</p>
          <p>{order.billingAddress?.landmark}</p>
          <p>
            {order.billingAddress?.city + "," + order.billingAddress?.pincode}
          </p>
        </div>
        {/* <p>Better Quality Better Health</p> */}
      </div>
    </div>
  );
});

export default ThermalInvoice;
